// ./controllers/flatpickr_controller.js
import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/themes/light.css";

import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { english } from "flatpickr/dist/l10n/default.js";

// creates a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  locales = {
      ja: Japanese,
      en: english
    };

  connect() {
    this.config = {
      locale: this.locale
    };
    super.connect();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return Japanese;
    }
  }
}
