import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "acceptor", "checker" ]

  toggle(event) {
    let acceptor_class = event.target.dataset['acceptor']
    this.acceptorTargets.forEach(function(el) {
      if (acceptor_class.length == 0) {
        el.classList.remove('hidden')
      } else {
        if (el.classList.contains(acceptor_class)) {
          el.classList.remove('hidden')
        } else {
          el.classList.add('hidden')
        }
      }
    })
  }
}
