import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checker", "forms", "numSelected" ];

  connect() {
    if (this.data) {
      let form_id = this.data.get('defaultFormId');
      if (form_id) {
        this.checkerTargets.forEach(function(el) {
          el.setAttribute('form', form_id);
        });
      }
    }
  }

  change(event) {
    var len = 0;
    this.checkerTargets.forEach(function(el) {
      if (el.checked) {
        el.parentElement.parentElement.style.fontWeight = 'bold';
        len += 1;
      } else {
        el.parentElement.parentElement.style.fontWeight = 'normal';
      }
    });
    this.numSelectedTarget.innerHTML = len;
    if (len > 0) {
      this.formsTarget.style.display = 'block';
    } else {
      this.formsTarget.style.display = 'none';
    }
  }

  change_form(event) {
    this.checkerTargets.forEach(function(el) {
      el.setAttribute('form', event.target.dataset.formId);
    });
  }
}
