import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  change(event) {
    this.outputTargets.forEach(function(el) {
      let checked = event.target.checked
      if (checked) {
        if (!el.closest('.hidden')) {
          el.checked = checked
        }
      } else {
        el.checked = checked
      }
    })
  }
}
